import React, { useState } from 'react'
import { PaymentIntent } from '@stripe/stripe-js'
import { currencyLookup, formatVal, typechecker } from '../../utils'
import ProceedButton from '../ProceedButton'
import WalletButton from '../WalletButton'

export interface ITicketSelectorPanelProps {
  availableTickets: ITicket[]
  selectedTicket: ITicket | boolean
  setSelectedTicket: (ticket: ITicket | false) => void
  quantitySelected: number
  setQuantitySelected: (quantity: number) => void
  event: IEvent
  onContinue: (
    paymentIntent?: PaymentIntent,
    billingDetails?: IBillingDetails,
  ) => void
  iconBackOrClose: boolean
  setIconBackOrClose: (state: boolean) => void
}

function TicketSelectorPanel(
  props: ITicketSelectorPanelProps,
): React.ReactElement {
  const {
    availableTickets,
    selectedTicket,
    setSelectedTicket,
    quantitySelected,
    setQuantitySelected,
    event,
    onContinue,
    setIconBackOrClose,
    iconBackOrClose,
  } = props

  const [bulletChecked, setBulletChecked] = useState(false)

  const selectTicket = (ticket: ITicket | false) => {
    setSelectedTicket(ticket)
    setIconBackOrClose(!iconBackOrClose)
  }

  const purchaseableTickets = (ticket: ITicket) => {
    const { MaxPerTransaction, Remaining } = ticket
    if (MaxPerTransaction) {
      if (MaxPerTransaction > Remaining) {
        return Remaining
      } else if (MaxPerTransaction <= 5) {
        return MaxPerTransaction
      } else return 5
    }
    // selectedTicket.MaxPerTransaction &&
    //                   selectedTicket.MaxPerTransaction <= 5
    //                   ? selectedTicket.MaxPerTransaction
    //                   : 5
  }

  const renderSelectedTicket = () => {
    if (selectedTicket && typechecker.isTicket(selectedTicket)) {
      return (
        <div
          className={`ticketQuantitySelectorContainer ${
            !selectedTicket && 'hidden'
          }`}
        >
          {selectedTicket.MaxPerTransaction > 1 && (
            <div className="ticketQuantitySelector">
              <h2 className="ticketQuantityLabel">Quantity</h2>
              <div className="ticketQuantityButtonsContainer">
                {Array.from(
                  Array(purchaseableTickets(selectedTicket)).keys(),
                ).map((val) => (
                  <div
                    className={`ticketQuantityButton ${
                      val + 1 === quantitySelected && 'selected'
                    }`}
                    key={val + 1}
                    onClick={() => {
                      setQuantitySelected(val + 1)
                      setBulletChecked(false)
                    }}
                    role="button"
                    tabIndex={0 - (val + 1)}
                  >
                    {val + 1}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="ticketOrderSummaryContainer">
            {selectedTicket.BasePrice > 0 && (
              <>
                <div className="ticketOrderSummaryRow">
                  <h4 className="ticketOrderSummaryRowTitle">
                    {quantitySelected}x {selectedTicket.Name}
                  </h4>
                  <h4 className="ticketOrderSummaryRowFigure">
                    {formatVal(
                      selectedTicket.BasePrice * quantitySelected,
                      currencyLookup(selectedTicket.Currency),
                    )}
                  </h4>
                </div>
                {selectedTicket.DiscountedAmount > 0 && (
                  <div className="ticketOrderSummaryRow discount">
                    <h4 className="ticketOrderSummaryRowTitle">Promo Code</h4>
                    <h4 className="ticketOrderSummaryRowFigure">
                      -{' '}
                      {formatVal(
                        (selectedTicket.BasePrice -
                          selectedTicket.DiscountedAmount) *
                          quantitySelected,
                        currencyLookup(selectedTicket.Currency),
                      )}
                    </h4>
                  </div>
                )}
                <div className="ticketOrderSummaryRow">
                  <h4 className="ticketOrderSummaryRowTitle">Booking Fees</h4>
                  <h4 className="ticketOrderSummaryRowFigure">
                    {formatVal(
                      selectedTicket.Fees * quantitySelected,
                      currencyLookup(selectedTicket.Currency),
                    )}
                  </h4>
                </div>
                <div className="ticketOrderSummaryRow total">
                  <h3 className="ticketOrderSummaryRowTitle">Total</h3>
                  <h3 className="ticketOrderSummaryRowFigure">
                    {formatVal(
                      selectedTicket.SalePrice * quantitySelected,
                      currencyLookup(selectedTicket.Currency),
                    )}
                  </h3>
                </div>
              </>
            )}
          </div>
          <div
            className="ticketQuantityDisclaimerContainer"
            onClick={() => setBulletChecked(!bulletChecked)}
            role="button"
            tabIndex={-10}
          >
            <div className="ticketQuantityDisclaimerButton">
              <svg
                className={`bulletCheck ${bulletChecked && 'checked'}`}
                data-name="bulletCheck"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
              >
                <path
                  className="bullet"
                  d="M11,22A11,11,0,1,1,22,11,10.96832,10.96832,0,0,1,11,22ZM11,2a9,9,0,1,0,9,9A8.96258,8.96258,0,0,0,11,2Z"
                />
                <circle className="bulletChecked" cx="11" cy="11" r="5" />
              </svg>
            </div>
            <p className="ticketQuantityDisclaimer">
              This booking is facilitated by{' '}
              <a
                href="https://scopetickets.com"
                target="_blank"
                rel="noreferrer"
              >
                Scope Tickets
              </a>
              . Please confirm that you have read, understood and agree to our{' '}
              <a
                href="https://scopetickets.com/legals/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href="https://scopetickets.com/legals/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              .
            </p>
          </div>

          {bulletChecked && selectedTicket.PaymentsEnabled && (
            <WalletButton
              ticket={selectedTicket as ITicket}
              quantity={quantitySelected}
              event={event}
              onContinue={(
                paymentIntent: PaymentIntent,
                billingDetails: IBillingDetails,
              ) => onContinue(paymentIntent, billingDetails)}
            />
          )}
          <ProceedButton blocked={!bulletChecked} onClick={() => onContinue()}>
            {selectedTicket.BasePrice > 0 ? 'Pay with card' : 'Book Now'}
          </ProceedButton>
        </div>
      )
    } else return <></>
  }

  return (
    <div>
      {availableTickets &&
        availableTickets.map((ticket, idx) => (
          <div
            className={`checkoutTicketType${
              selectedTicket !== false
                ? selectedTicket === ticket
                  ? ' selected'
                  : ' hidden'
                : ''
            }${
              ticket.Remaining <= 0 || !ticket.Available ? ' isUnavailable' : ''
            }${ticket.DiscountedAmount > 0 ? ' discounted' : ''}`}
            onClick={(e) => {
              if (ticket.Remaining > 0 && ticket.Available) {
                if (!selectedTicket) {
                  selectTicket(ticket)
                } else {
                  e.stopPropagation()
                }
              }
            }}
            role="button"
            tabIndex={idx}
            key={idx}
          >
            <TicketBlock
              ticket={ticket}
              formatVal={formatVal}
              setSelectedTicket={setSelectedTicket}
            />
          </div>
        ))}

      {renderSelectedTicket()}
    </div>
  )
}

function TicketBlock(props: {
  ticket: ITicket
  formatVal: (value: number, currency?: string) => string
  setSelectedTicket: (ticket: ITicket | false) => void
}) {
  // const { setTicket } = useTicket()
  const { ticket, formatVal } = props
  return (
    <>
      <div className="ticketInformation">
        <div className="ticketDescription">
          <div className="ticketName">
            <h2>{ticket.Name}</h2>
          </div>
          <div className="ticketPrice">
            <h3 className="ticketPrice">
              {ticket.BasePrice > 0 ? (
                <>
                  <span className="ticketCurrency">
                    {currencyLookup(ticket.Currency)}
                  </span>
                  <span className="ticketBasePrice">
                    {formatVal(ticket.BasePrice)}
                  </span>
                  {ticket.DiscountedAmount > 0 ? (
                    <>
                      <span className="ticketCurrency discounted">
                        {currencyLookup(ticket.Currency)}
                      </span>
                      <span className="ticketDiscountedPrice discounted">
                        {formatVal(ticket.DiscountedAmount)}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <span className="ticketFees minor">
                    + {formatVal(ticket.Fees, currencyLookup(ticket.Currency))}{' '}
                    booking fee
                  </span>
                </>
              ) : (
                <span className="ticketBasePrice">Free</span>
              )}
            </h3>
          </div>
        </div>
        <div
          className="ticketIcon"
          onClick={() => {
            console.log('Selected Ticket')
            props.setSelectedTicket(false)
          }}
          role="button"
          tabIndex={-1}
        >
          <svg
            id="iconProceed"
            data-name="iconProceed"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <path d="M58.2483,53.53553a5,5,0,0,0,0-7.07106l-44.023-44.023A8.33244,8.33244,0,0,0,2.44141,14.22527L38.21613,50,2.44141,85.77473A8.33244,8.33244,0,1,0,14.22526,97.55858Z" />
          </svg>
          <svg
            id="iconCancel"
            data-name="iconCancel"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <path d="M61.78385,50,97.55858,14.22526A8.33244,8.33244,0,0,0,85.77473,2.44141L50,38.21613,14.22527,2.44141A8.33244,8.33244,0,0,0,2.44142,14.22526L38.21615,50,2.44142,85.77471A8.33244,8.33244,0,1,0,14.22527,97.55856L50,61.78383,85.77473,97.55856A8.33244,8.33244,0,0,0,97.55858,85.77471Z" />
          </svg>
          <h3 className="isUnavailableMessage">
            {ticket.Status == 'sold out' ? 'Sold Out' : 'Expired'}
          </h3>
        </div>
      </div>
      <div className="ticketNote">
        <p>{ticket.Note}</p>
      </div>
    </>
  )
}

export default TicketSelectorPanel
