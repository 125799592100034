/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { API, useEvent } from '../../utils'
import InputField from '../InputField'
import ProceedButton from '../ProceedButton'

interface IAttendeeDetailsFormProps {
  onComplete: (guestDetails: IAttendee) => void
  attendeeTicket: IAttendeeTicket
}

const AttendeeDetailsForm = (
  props: IAttendeeDetailsFormProps,
): React.ReactElement => {
  const { event } = useEvent().eventState
  const [guestDetails, setGuestDetails] = useState<IAttendee>({
    ID: props.attendeeTicket.AttendeeID || 0,
    FirstName: '',
    LastName: '',
    Email: '',
  })
  const [detailsRequired, setDetailsRequired] = useState(false)
  const [formEntriesValid, setFormEntriesValid] = useState(false)
  const checkFormEntriesValid = () => {
    if (guestDetails.FirstName === '' || guestDetails.LastName === '')
      setFormEntriesValid(false)
    setFormEntriesValid(true)
  }
  useEffect(() => {
    API.get<ITicket>(`Ticket/Get/${props.attendeeTicket.TicketID}`).then(
      (response) => setDetailsRequired(response.data.AttendeeDetailsRequired),
    )
    API.get<IAttendee>(`Attendee/Get/${props.attendeeTicket.TicketID}`).then(
      (response) => {
        setGuestDetails({
          ID: props.attendeeTicket.AttendeeID || 0,
          FirstName: response.data.FirstName || '',
          LastName: response.data.LastName || '',
          Email: response.data.Email || '',
        })
      },
    )
  }, [props.attendeeTicket])

  const handleComplete = async () => {
    if (detailsRequired && !formEntriesValid) return
    return API.get<string>(
      `Utils/GenerateAvatar?firstName=${guestDetails.FirstName}&lastName=${
        guestDetails.LastName
      }&background_color=${event!.ColourSecondary.split('#')[1]}&font_color=${
        event!.ColourBackground.split('#')[1]
      }`,
    ).then((response) => {
      return props.onComplete({ ...guestDetails, Photo: response.data })
    })
  }
  return (
    <>
      <form className="formGuestDetails">
        <div className="form-row form-row-double">
          <InputField
            type="text"
            id="addAttendeeFirstName"
            name="addAttendeeFirstName"
            placeholder="First Name"
            label="First Name"
            required
            value={guestDetails.FirstName}
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setGuestDetails({
                ...guestDetails,
                FirstName: target !== null ? target.value : '',
              })
              checkFormEntriesValid()
            }}
          />
          <InputField
            type="text"
            id="addAttendeeLastName"
            name="addAttendeeLastName"
            placeholder="Last Name"
            label="Last Name"
            required
            value={guestDetails.LastName}
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setGuestDetails({
                ...guestDetails,
                LastName: target !== null ? target.value : '',
              })
              checkFormEntriesValid()
            }}
          />
        </div>
        <div className="formInstructionText">
          <h4>
            Please add an email address if you would like us to send this guest
            their ticket.
          </h4>
        </div>
        <div className="form-row">
          <InputField
            type="email"
            id="addAttendeeEmail"
            name="addAttendeeEmail"
            placeholder="Email"
            label="Email"
            required
            value={guestDetails.Email}
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setGuestDetails({
                ...guestDetails,
                Email: target !== null ? target.value : '',
              })
              checkFormEntriesValid()
            }}
          />
        </div>
      </form>
      <ProceedButton
        blocked={detailsRequired ? !formEntriesValid : false}
        onClick={handleComplete}
      >
        Continue
      </ProceedButton>
    </>
  )
}

export default AttendeeDetailsForm
