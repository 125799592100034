import { createContext, useContext } from 'react'
interface IProcessingContextType {
  processing: boolean
  setProcessing: (state: boolean) => void
}
export const ProcessingContext = createContext<IProcessingContextType>({
  processing: false,
  setProcessing: (state) => console.warn('no provider', state),
})
ProcessingContext.displayName = 'ProcessingContext'
export const useProcessing = (): IProcessingContextType =>
  useContext(ProcessingContext)
