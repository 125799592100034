import React from 'react'

function ScopeLogo(props: {
  style: React.CSSProperties
  fill: string
}): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      x="0"
      y="0"
      enableBackground="new 0 0 620.2 348.4"
      version="1.1"
      viewBox="0 0 620.2 348.4"
      xmlSpace="preserve"
      style={props.style}
    >
      <path
        d="M620.2 38.3L581.8-.1l-.1.1-6.9 7-14.1 14.1C546 34.9 446.3 123.8 310.2 123.8c-33.3 0-67.2-5.4-100.6-16-27.1-8.6-54-20.6-80-35.7C85 46.2 58.2 19.8 57.7 19.2L38.5 0l-.1.1V0L16.5 21.9 0 38.3l.1.1H0l310.1 310L620.2 38.3zM200.3 161.8c36.4 10.8 73.3 16.3 109.8 16.3s73.4-5.5 109.8-16.3L310.1 271.6 200.3 161.8z"
        style={{ fill: props.fill }}
      ></path>
    </svg>
  )
}

export default ScopeLogo
