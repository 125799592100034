import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { DEBUG } from '../../constants'
import { getContext, storeContext } from '../contextStorage.util'
import {
  BillingDetailsActions,
  billingDetailsReducer,
  PaymentIntentActions,
  paymentIntentReducer,
  PurchasedTicketActions,
  purchasedTicketReducer,
  QuantityActions,
  quantityReducer,
  TicketActions,
  ticketReducer,
} from './reducers/ticket.reducer'

type InitialStateType = {
  ticket: ITicket | boolean
  quantity: number
  billingDetails: IBillingDetails | null
  purchasedTickets: IAttendeeTicket[] | undefined
  paymentIntent: import('@stripe/stripe-js').PaymentIntent | null
}

const nullState: InitialStateType = {
  ticket: false,
  quantity: 1,
  billingDetails: null,
  paymentIntent: null,
  purchasedTickets: undefined,
}

const initialState = () => {
  const ticket = getContext<InitialStateType>('ticket')
  if (ticket && ticket.context) {
    if (Date.now() < ticket.exp) {
      DEBUG && console.log('loaded ticket from storage')
      return ticket.context
    } else return nullState
  } else return nullState
}

export const TicketContext = createContext<{
  ticketState: InitialStateType
  ticketDispatch: React.Dispatch<
    | TicketActions
    | QuantityActions
    | BillingDetailsActions
    | PaymentIntentActions
    | PurchasedTicketActions
  >
}>({ ticketState: initialState(), ticketDispatch: () => null })
TicketContext.displayName = 'TicketContext'
export const useTicket = () => useContext(TicketContext)

const ticketContextReducer = (
  {
    ticket,
    quantity,
    billingDetails,
    paymentIntent,
    purchasedTickets,
  }: InitialStateType,
  action:
    | TicketActions
    | QuantityActions
    | BillingDetailsActions
    | PaymentIntentActions
    | PurchasedTicketActions,
) => ({
  ticket: ticketReducer(ticket, action),
  quantity: quantityReducer(quantity, action),
  billingDetails: billingDetailsReducer(billingDetails, action),
  paymentIntent: paymentIntentReducer(paymentIntent, action),
  purchasedTickets: purchasedTicketReducer(purchasedTickets, action),
})

export const TicketProvider: React.FC = ({ children }) => {
  const [ticketState, ticketDispatch] = useReducer(
    ticketContextReducer,
    initialState(),
  )

  useEffect(() => {
    // sessionStorage.setItem('ticket', window.btoa(JSON.stringify(ticketState)))
    storeContext<InitialStateType>('ticket', ticketState)
  }, [ticketState])

  return (
    <TicketContext.Provider value={{ ticketState, ticketDispatch }}>
      {children}
    </TicketContext.Provider>
  )
}
