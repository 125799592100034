import React, { useEffect, useState } from 'react'
import { API } from '../../utils'
import AttendeeDetailsForm from './AttendeeDetailsForm'

export interface IAttendeeDetailPanelProps {
  purchasedTickets: IAttendeeTicket[]
  billingDetails: IBillingDetails
  activeGuest: number
  setActiveGuest: (activeGuest: number) => void
  onContinue: () => void
}

export function TicketIcon(props: {
  index?: number
  attendeeTicket: IAttendeeTicket
  activeIdx?: number
}): React.ReactElement {
  const [attendee, setAttendee] = useState<IAttendee>()
  useEffect(() => {
    API.get<IAttendee>(`Attendee/Get/${props.attendeeTicket.AttendeeID}`).then(
      (response) => {
        setAttendee(response.data)
      },
    )
  }, [props.attendeeTicket, props.activeIdx])
  return (
    <div
      className={`ticketQuantityIcon ${
        props.index && props.activeIdx
          ? props.index === props.activeIdx
            ? 'active'
            : props.index < props.activeIdx
            ? 'completed'
            : ''
          : 'completed'
      }`}
    >
      {attendee?.FirstName ? (
        <img style={{ width: 40 }} src={attendee.Photo} alt="" />
      ) : (
        props.index
      )}
    </div>
  )
}

const AttendeeDetailPanel = (
  props: IAttendeeDetailPanelProps,
): React.ReactElement => {
  const { activeGuest, setActiveGuest } = props
  async function handleFormComplete(guestDetails: IAttendee) {
    // console.log('guest details', guestDetails.toString())
    await API.post<IAttendee[]>('Attendee/Save', [guestDetails])
    API.post(`AttendeeTicket/SendEmail`, [
      {
        Email:
          guestDetails.Email !== ''
            ? guestDetails.Email
            : props.billingDetails.email,
        AttendeeTicketId: props.purchasedTickets[activeGuest].ID,
      },
    ])
    if (activeGuest === props.purchasedTickets.length - 1) {
      props.onContinue()
    } else setActiveGuest(activeGuest + 1)
  }
  if (props.purchasedTickets.length < 2) {
    props.onContinue()
    return <></>
  }
  return (
    <>
      <div className="ticketQuantityDisplay">
        <div className="ticketQuantityIconsContainer">
          {activeGuest &&
            props.purchasedTickets.map((ticket, idx) => (
              <TicketIcon
                index={idx}
                attendeeTicket={ticket}
                activeIdx={activeGuest}
                key={ticket.ID}
              />
            ))}
        </div>
      </div>
      <div className="appCheckoutStepHeading">
        <h1 className="appCheckoutStepTitle">Add Guest Details</h1>
        <p className="appCheckoutStepDescription">
          You have {props.purchasedTickets.length - activeGuest} other tickets
          with no attendee details
        </p>
      </div>
      <AttendeeDetailsForm
        onComplete={handleFormComplete}
        attendeeTicket={props.purchasedTickets[activeGuest]}
      />
    </>
  )
}
export default AttendeeDetailPanel
