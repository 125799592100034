import React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './styles.css'
// import { EventContext, TicketContext } from './utils'

Sentry.init({
  dsn: 'https://f83d12483fcf428e8a3641c6fda376b8@o273010.ingest.sentry.io/5603939',
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
