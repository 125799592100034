import React from 'react'

const InputField = (props: {
  label?: string
  id?: string
  type?: string
  placeholder?: string
  required?: boolean
  autoComplete?: string
  value?: string | number | string[]
  name?: string
  onChange?: React.ChangeEventHandler
  disabled?: boolean
  style?: React.CSSProperties
}): React.ReactElement => (
  <div
    className={`form-field ${props.disabled ? 'disabled' : ''} ${
      props.value ? 'isNotEmpty' : ''
    }`}
  >
    <label htmlFor={props.id}>{props.label}</label>
    <input
      id={props.id}
      type={props.type}
      placeholder={props.placeholder}
      required={props.required}
      autoComplete={props.autoComplete}
      value={props.value}
      onChange={props.onChange}
      style={props.style}
    />
  </div>
)
export default InputField
