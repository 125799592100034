import React from 'react'

import './ProgressBlips.css'
export interface IProgressBlipsProps {
  activeBlip: number
  stepCount: number
}

function ProgressBlips(props: IProgressBlipsProps): React.ReactElement {
  return (
    <>
      <div className="progress-blips">
        {[...Array(props.stepCount)].map((step, idx) => (
          <div
            key={idx}
            id={`${idx}`}
            className={`step-blip${props.activeBlip === idx ? ' active' : ''} ${
              props.activeBlip > idx ? 'complete' : ''
            }`}
          />
        ))}
      </div>
    </>
  )
}

export default ProgressBlips
