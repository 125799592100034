export enum TicketReducerTypes {
  SetTicket = 'SET_TICKET',
  SetQuantity = 'SET_QUANTITY',
  SetBillingDetails = 'SET_BILLING',
  SetPaymentIntent = 'SET_PAYMENT_INTENT',
  SetPurchasedTicket = 'SET_PURCHASED_TICKET',
}

type TicketPayload = {
  [TicketReducerTypes.SetTicket]: ITicket | boolean
}

type QuantityPayload = {
  [TicketReducerTypes.SetQuantity]: number
}

type BillingDetailsPayload = {
  [TicketReducerTypes.SetBillingDetails]: IBillingDetails | null
}

type PurchasedTicketPayload = {
  [TicketReducerTypes.SetPurchasedTicket]: IAttendeeTicket[] | undefined
}

type PaymentIntentPayload = {
  [TicketReducerTypes.SetPaymentIntent]:
    | import('@stripe/stripe-js').PaymentIntent
    | null
}

export type TicketActions =
  ActionMap<TicketPayload>[keyof ActionMap<TicketPayload>]
export type QuantityActions =
  ActionMap<QuantityPayload>[keyof ActionMap<QuantityPayload>]
export type BillingDetailsActions =
  ActionMap<BillingDetailsPayload>[keyof ActionMap<BillingDetailsPayload>]
export type PaymentIntentActions =
  ActionMap<PaymentIntentPayload>[keyof ActionMap<PaymentIntentPayload>]
export type PurchasedTicketActions =
  ActionMap<PurchasedTicketPayload>[keyof ActionMap<PurchasedTicketPayload>]

export const ticketReducer = (
  state: ITicket | boolean,
  action:
    | TicketActions
    | QuantityActions
    | BillingDetailsActions
    | PaymentIntentActions
    | PurchasedTicketActions,
) => {
  switch (action.type) {
    case TicketReducerTypes.SetTicket:
      return action.payload
    default:
      return state
  }
}

export const quantityReducer = (
  state: number,
  action:
    | TicketActions
    | QuantityActions
    | BillingDetailsActions
    | PaymentIntentActions
    | PurchasedTicketActions,
) => {
  switch (action.type) {
    case TicketReducerTypes.SetQuantity:
      return action.payload
    default:
      return state
  }
}

export const billingDetailsReducer = (
  state: IBillingDetails | null,
  action:
    | TicketActions
    | QuantityActions
    | BillingDetailsActions
    | PaymentIntentActions
    | PurchasedTicketActions,
) => {
  switch (action.type) {
    case TicketReducerTypes.SetBillingDetails:
      return action.payload
    default:
      return state
  }
}

export const paymentIntentReducer = (
  state: import('@stripe/stripe-js').PaymentIntent | null,
  action:
    | TicketActions
    | QuantityActions
    | BillingDetailsActions
    | PaymentIntentActions
    | PurchasedTicketActions,
) => {
  switch (action.type) {
    case TicketReducerTypes.SetPaymentIntent:
      return action.payload
    default:
      return state
  }
}

export const purchasedTicketReducer = (
  state: IAttendeeTicket[] | undefined,
  action:
    | TicketActions
    | QuantityActions
    | BillingDetailsActions
    | PaymentIntentActions
    | PurchasedTicketActions,
) => {
  switch (action.type) {
    case TicketReducerTypes.SetPurchasedTicket:
      return action.payload
    default:
      state
  }
}
