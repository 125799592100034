import React, { useEffect, useState } from 'react'
import { API } from '../../utils'
import TicketPurchased from './TicketPurchased'

export interface ITicketPurchasedSliderProps {
  purchasedTickets: IAttendeeTicket[]
}

export default function TicketPurchasedSlider(
  props: ITicketPurchasedSliderProps,
): React.ReactElement {
  const [ticket, setTicket] = useState<ITicket>()
  useEffect(() => {
    API.get<ITicket>(`Ticket/Get/${props.purchasedTickets[0].TicketID}`).then(
      (response) => {
        setTicket(response.data)
      },
    )
  }, [props.purchasedTickets])
  return (
    <div className="ticketsPurchasedSlider">
      {ticket &&
        props.purchasedTickets.map((purchasedTicket, idx) => (
          <TicketPurchased
            key={purchasedTicket.ID}
            purchasedAttendeeTicket={purchasedTicket}
            ticket={ticket}
            activeTicket={idx}
            ticketCount={props.purchasedTickets.length}
          />
        ))}
    </div>
  )
}
