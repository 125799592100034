import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import ScopeLogo from '../../assets/scopeLogo'
import * as Sentry from '@sentry/react'
import { API, hexToRgb } from '../../utils'
import TicketPurchasedSlider from '../../components/TicketPurchasedSlider'
import { EventProvider } from '../../utils/contexts/event.context'

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fallback: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
}

export function TicketViewer() {
  const [processing, setProcessing] = React.useState(true)
  const [eventInfo, setEventInfo] = React.useState<IEvent>()
  const [purchasedTickets, setPurchasedTickets] =
    React.useState<IAttendeeTicket[]>()

  const { encodedTickets } = useParams<{ encodedTickets: string }>()
  React.useEffect(() => {
    document.body.style.height = `${window.innerHeight}px`
    window.addEventListener(
      'resize',
      () => (document.body.style.height = `${window.innerHeight}px`),
    )
    return () =>
      window.removeEventListener(
        'resize',
        () => (document.body.style.height = `${window.innerHeight}px`),
      )
  }, [window.innerHeight])

  React.useEffect(() => {
    const decodedJSON: IAttendeeTicket[] = JSON.parse(
      window.atob(encodedTickets),
    )
    setPurchasedTickets(decodedJSON)
    API.get<IEvent>(`Events/Get/${decodedJSON[0].EventID}`)
      .then((response) => {
        setProcessing(false)
        if (response.data !== null) {
          setEventInfo(response.data)
          // Setting Colours
          document.documentElement.style.setProperty(
            '--background-colour',
            hexToRgb(response.data.ColourBackground),
          )
          document.documentElement.style.setProperty(
            '--primary-colour',
            hexToRgb(response.data.ColourPrimary),
          )
          document.documentElement.style.setProperty(
            '--secondary-colour',
            hexToRgb(response.data.ColourSecondary),
          )
          document.documentElement.style.setProperty(
            '--detail-colour',
            hexToRgb(response.data.ColourDetail),
          )
          document.documentElement.style.setProperty(
            '--system-colour',
            hexToRgb(response.data.SystemColour),
          )
          document.documentElement.style.setProperty(
            '--background-url',
            `url('${response.data.Background}')`,
          )
          Sentry.addBreadcrumb({
            category: 'message',
            message: `Loaded event ${decodedJSON[0].EventID} details succesfully.`,
            level: Sentry.Severity.Info,
          })
        }
      })
      .catch((e) => Sentry.captureException(e))
  }, [encodedTickets])

  if (processing) {
    return (
      <div className="wrapper">
        <div className="container" style={styles.container}>
          <div className="fallback" style={styles.fallback}>
            <ScopeLogo
              fill="#0500F2"
              style={{
                height: '60px',
                padding: '20px',
                marginBottom: '20px',
              }}
            />
            <h1>Loading your experience...</h1>
            <p>
              If you are waiting for too long check your internet or ensure you
              have copied the correct url
            </p>
          </div>
        </div>
      </div>
    )
  }
  if (!processing && eventInfo && purchasedTickets) {
    return (
      <div id="wrapper" className={'locked'}>
        <Helmet>
          <title>
            {eventInfo ? `${eventInfo.Name} | Scope Tickets` : 'Scope Tickets'}
          </title>
          <meta property="og:image" content={eventInfo.Cover} />
          <meta name="theme-color" content={eventInfo.ColourBackground} />
        </Helmet>
        <div className="appLandingContainer">
          <section className="eventArtwork">
            <div className="artworkHeroContainer">
              <div className="artworkHero">
                <img src={eventInfo.Background} alt="Event Background" />
              </div>
            </div>
            <div className="artworkBackground">
              <div className="artworkBackgroundGradient" />
              <img src={eventInfo.Background} alt="Event Background" />
            </div>
          </section>
          <EventProvider>
            <div className={`appCheckout active`}>
              <div className="appCheckoutContainer">
                {/* <div className="cardNavigationControls"></div> */}

                <div className="appCheckoutEventOverview innerWidth">
                  <div className="appCheckoutEventOverviewImage">
                    <img src={eventInfo.Background} alt="Event Background" />
                  </div>
                  <div className="appCheckoutEventOverviewText">
                    <h3>
                      {Intl.DateTimeFormat(navigator.language, {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }).format(Date.parse(eventInfo.Date))}
                    </h3>
                    <h1>{eventInfo.Name}</h1>
                  </div>
                </div>
                <div className="appCheckoutCard final">
                  <div className="appCheckoutStep active" id="checkoutStep6">
                    {purchasedTickets && (
                      <TicketPurchasedSlider
                        purchasedTickets={purchasedTickets}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </EventProvider>
        </div>
      </div>
    )
  }
  return <>Error</>
}
