import React from 'react'
import LoadingAnimate from '../../assets/LoadingAnimate'
import { useProcessing } from '../../utils/contexts/ProcessingContext'

interface IProceedButtonProps {
  blocked?: boolean
  onClick?: React.MouseEventHandler
  muted?: boolean
  hidden?: boolean
  children: string
}

function ProceedButton(props: IProceedButtonProps): React.ReactElement {
  const { processing } = useProcessing()
  return (
    <div
      className={`buttonProceed${props.blocked ? ' blocked' : ''}${
        props.muted ? ' muted' : ''
      }${props.hidden ? ' hidden' : ''}`}
      onClick={props.onClick}
    >
      {processing ? (
        <LoadingAnimate fill={'rgba(0,0,0,1)'} height={20} />
      ) : (
        <p>{props.children}</p>
      )}
    </div>
  )
}

export default ProceedButton
