import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { DEBUG } from '../../constants'
import { getContext, storeContext } from '../contextStorage.util'
import {
  ProgressPageActions,
  progressPageReducer,
  ProgressPropsActions,
  progressPropsReducer,
  ProgressStageActions,
  progressStageReducer,
} from './reducers/progress.reducer'

export enum ProgressPageEnum {
  LandingPage = 'LANDING',
  WaitingListPage = 'WAITING',
  CheckoutPage = 'CHECKOUT',
  TicketPage = 'TICKET',
}

type InitialProgressStateType = {
  page: ProgressPageEnum // [landing, waiting, checkout, ticket]
  stage: number // 1 or checkout stage number
  props: unknown
}

const initialProgressState = {
  page: ProgressPageEnum.LandingPage,
  stage: 1,
  props: {},
}

const initialState = () => {
  const progress = getContext<InitialProgressStateType>('progress')
  if (progress && progress.context) {
    if (Date.now() < progress.exp) {
      DEBUG && console.log('loaded progress from storage')
      return progress.context
    } else return initialProgressState
  } else return initialProgressState
}

export const ProgressContext = createContext<{
  progressState: InitialProgressStateType
  progressDispatch: React.Dispatch<
    ProgressStageActions | ProgressPageActions | ProgressPropsActions
  >
}>({
  progressState: initialState(),
  progressDispatch: () => null,
})
ProgressContext.displayName = 'ProgressContext'

const progressContextReducer = (
  { page, stage, props }: InitialProgressStateType,
  action: ProgressPageActions | ProgressStageActions | ProgressPropsActions,
) => ({
  page: progressPageReducer(page, action),
  stage: progressStageReducer(stage, action),
  props: progressPropsReducer(props, action),
})

export const useProgress = () => useContext(ProgressContext)
export const ProgressProvider: React.FC = ({ children }) => {
  const [progressState, progressDispatch] = useReducer(
    progressContextReducer,
    initialState(),
  )
  const location = useLocation()
  const history = useHistory()

  const expectedURLregex = (state: InitialProgressStateType) => {
    DEBUG && console.log(location.pathname, state.page)
    switch (state.page) {
      case ProgressPageEnum.LandingPage:
        return /\/$/gm
      case ProgressPageEnum.CheckoutPage:
        switch (state.stage) {
          case 1:
            return /\/checkout$/gm
          case 2:
            return /\/checkout\/pay$/gm
          case 3:
            return /\/checkout\/contact$/gm
          case 4:
            return /\/checkout\/guests$/gm
          case 5:
            return /\/checkout\/confirm$/gm
          case 6:
            return /\/checkout\/tickets$/gm
          default:
            return /\/checkout$/gm
        }
      case ProgressPageEnum.WaitingListPage:
        return /\/waiting$/gm
      case ProgressPageEnum.TicketPage:
        return /\/tickets\/((?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$)$/gm
      default:
        return /\/$/gm
    }
  }

  const redirectUrl = (state: InitialProgressStateType) => {
    switch (state.page) {
      case ProgressPageEnum.LandingPage:
        return '/'
      case ProgressPageEnum.CheckoutPage:
        switch (state.stage) {
          case 1:
            return '/checkout'
          case 2:
            return '/checkout/pay'
          case 3:
            return '/checkout/contact'
          case 4:
            return '/checkout/guests'
          case 5:
            return '/checkout/confirm'
          case 6:
            return '/checkout/tickets'
          default:
            return '/checkout'
        }
      default:
        return '/'
    }
  }

  useEffect(() => {
    // console.log(progressState)
    storeContext<InitialProgressStateType>('progress', progressState)
    // const expectedUrl = expectedURLregex(progressState)
    const isValidState = expectedURLregex(progressState).test(location.pathname)
    // console.log(isValidState)
    if (!isValidState && location.search)
      history.replace(redirectUrl(progressState) + location.search)
  }, [progressState])

  return (
    <ProgressContext.Provider value={{ progressState, progressDispatch }}>
      {children}
    </ProgressContext.Provider>
  )
}
