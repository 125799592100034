import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { DEBUG } from '../../constants'
import { getContext, storeContext } from '../contextStorage.util'
import {
  EventActions,
  eventReducer,
  PromoCodeActions,
  promoCodeReducer,
} from './reducers/event.reducer'

type InitialStateType = {
  event: IEvent | Record<string, never>
  promoCode: string
}

const initialEventState = {
  event: {} as IEvent,
  promoCode: '',
}

const initialState = () => {
  // const event = sessionStorage.getItem('event')
  const eventObj = getContext<InitialStateType>('event')
  if (eventObj) {
    const { context, exp } = eventObj
    if (context.event !== {}) {
      if (Date.now() < exp) {
        const params = new URLSearchParams(window.location.search)
        if (context.event && context.event.ID)
          if (params.get('eventId') === context.event.ID.toString()) {
            DEBUG && console.log('loaded event from storage')
            return context
          }
        return initialEventState
      } else return initialEventState
    } else return initialEventState
  } else return initialEventState
}

export const EventContext = createContext<{
  eventState: InitialStateType
  eventDispatch: React.Dispatch<EventActions | PromoCodeActions>
}>({ eventState: initialState(), eventDispatch: () => null })
EventContext.displayName = 'EventContext'

const eventContextReducer = (
  { event, promoCode }: InitialStateType,
  action: EventActions | PromoCodeActions,
) => ({
  event: eventReducer(event, action),
  promoCode: promoCodeReducer(promoCode, action),
})

export const useEvent = () => useContext(EventContext)
export const EventProvider: React.FC = ({ children }) => {
  const [eventState, eventDispatch] = useReducer(
    eventContextReducer,
    initialState(),
  )

  useEffect(() => {
    storeContext<InitialStateType>('event', eventState)
  }, [eventState])

  return (
    <EventContext.Provider value={{ eventState, eventDispatch }}>
      {children}
    </EventContext.Provider>
  )
}
