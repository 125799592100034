let BACKEND_ENDPOINT = 'https://testlink.scopetickets.com'
let STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ''
let DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false
if (process.env.REACT_APP_PRODUCTION) {
  BACKEND_ENDPOINT =
    process.env.REACT_APP_PRODUCTION_BACKEND_ENDPOINT || BACKEND_ENDPOINT
  STRIPE_KEY = process.env.REACT_APP_PRODUCTION_STRIPE_KEY || STRIPE_KEY
  DEBUG = false
}
console.log('DEBUG', DEBUG)
const ANIMATION_PROPS = {
  initial: { opacity: 1, x: 1000, y: 0 },
  animate: { opacity: 1, x: 0, y: 0 },
  exit: {
    opacity: 0,
    x: -1000,
    y: 0,
    transition: { type: 'tween', duration: 0.25 },
  },
  transition: { type: 'tween', duration: 0.5 },
}
export { BACKEND_ENDPOINT, STRIPE_KEY, ANIMATION_PROPS, DEBUG }
