import React, { useState } from 'react'
import { sendFeedback } from '../../api-calls'
import { useTicket } from '../../utils'
import ProceedButton from '../ProceedButton'

interface IFeedbackFormProps {
  onContinue: () => void
}

function FeedbackForm(props: IFeedbackFormProps): React.ReactElement {
  const [selectedStar, setSelectedStar] = useState<number | undefined>()
  const [contactAllowed, setContactAllowed] = useState<boolean>(true)

  const {
    ticketState: { purchasedTickets },
  } = useTicket()

  const radioOptions = [
    { icon: '😠', score: 1 },
    { icon: '🙁', score: 2 },
    { icon: '😊', score: 3 },
    { icon: '🤩', score: 4 },
  ]

  const handleFeedbackPress = () => {
    console.log('Sending feedback:', { selectedStar, contactAllowed })
    if (selectedStar !== undefined && purchasedTickets !== undefined)
      sendFeedback(purchasedTickets[0].ID, selectedStar)
    props.onContinue()
  }
  return (
    <>
      <form className="feedbackContainer">
        <fieldset className="feedbackRatingContainer">
          {/* <!-- @Liam we're adding this as a field (and separate endpoint) to basically POST a number between 1 & 4 based on user rating  --> */}
          {radioOptions.map((option) => (
            <div
              className={`feedbackRatingOption ${
                selectedStar !== undefined
                  ? selectedStar === option.score
                    ? 'selected'
                    : 'muted'
                  : ''
              }`}
              key={option.score}
              onClick={() => setSelectedStar(option.score)}
            >
              <input
                type="radio"
                name="feedbackRating"
                id={`feedbackRatingScore-${option.score}`}
                value={option.score}
              />
              <label htmlFor={`feedbackRatingScore-${option.score}`}>
                <h1 className="feedbackRatingIcon">{option.icon}</h1>
                <h2 className="feedbackRatingScore">{option.score}</h2>
              </label>
            </div>
          ))}
        </fieldset>
        <fieldset
          className={`feedbackResponseContainer ${!selectedStar && 'hidden'}`}
        >
          <h4 className="feedbackResponseText">
            We&apos;re continuously working to improve this service. Is it ok if
            a representative messages you about your feedback?
          </h4>
          <input
            type="radio"
            id="feedbackResponseYes"
            name="feedbackResponse"
            value="Yes"
            checked={contactAllowed}
          />
          <label
            htmlFor="feedbackResponseYes"
            onClick={() => setContactAllowed(true)}
          >
            <div className="feedbackResponseOptionButton">
              <svg
                className={`bulletCheck ${contactAllowed ? 'checked' : ''}`}
                data-name="bulletCheck"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
              >
                <path
                  className="bullet"
                  d="M11,22A11,11,0,1,1,22,11,10.96832,10.96832,0,0,1,11,22ZM11,2a9,9,0,1,0,9,9A8.96258,8.96258,0,0,0,11,2Z"
                />
                <circle className="bulletChecked" cx="11" cy="11" r="5" />
              </svg>
            </div>
            <h2>Yes</h2>
          </label>
          <input
            type="radio"
            id="feedbackResponseNo"
            name="feedbackResponse"
            value="No"
            checked={!contactAllowed}
          />
          <label
            htmlFor="feedbackResponseNo"
            onClick={() => setContactAllowed(false)}
          >
            <div className="feedbackResponseOptionButton">
              <svg
                className={`bulletCheck ${!contactAllowed ? 'checked' : ''}`}
                data-name="bulletCheck"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
              >
                <path
                  className="bullet"
                  d="M11,22A11,11,0,1,1,22,11,10.96832,10.96832,0,0,1,11,22ZM11,2a9,9,0,1,0,9,9A8.96258,8.96258,0,0,0,11,2Z"
                />
                <circle className="bulletChecked" cx="11" cy="11" r="5" />
              </svg>
            </div>
            <h2>No</h2>
          </label>
        </fieldset>
        <input type="submit" />
        <label
          htmlFor="submit"
          className={`buttonProceed ${!selectedStar ? 'hidden' : ''}`}
          onClick={handleFeedbackPress}
        >
          <p>Submit Feedback</p>
        </label>
      </form>
      <ProceedButton onClick={() => props.onContinue()} muted>
        View Tickets
      </ProceedButton>
    </>
  )
}

export default FeedbackForm
