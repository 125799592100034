/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PaymentIntent } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import StripeCardForm from '../../components/StripeCardForm'
import ContactDetailsForm from '../../components/ContactDetailsForm'
import { API, useEvent, useTicket } from '../../utils'
import * as Sentry from '@sentry/react'
import AttendeeDetailPanel, {
  TicketIcon,
} from '../../components/AttendeeDetailPanel'
import FeedbackForm from '../../components/FeedbackForm'
import TicketSelectorPanel from '../../components/TicketSelectorPanel'
import ProgressBlips from '../../components/ProgressBlips'
import TicketPurchasedSlider from '../../components/TicketPurchasedSlider'
import { TicketReducerTypes } from '../../utils/contexts/reducers/ticket.reducer'
import { useProgress } from '../../utils/contexts/progress.context'
import { ProgressReducerTypes } from '../../utils/contexts/reducers/progress.reducer'

interface AppCheckoutV2Props {
  pageActive: boolean
  // onContinue: (currentStep: string) => void
  onBack: () => void
}
function AppCheckoutV2(props: AppCheckoutV2Props): React.ReactElement {
  const { event, promoCode } = useEvent().eventState
  const { ticketState, ticketDispatch } = useTicket()
  const {
    progressState: { stage: activeStep },
    progressDispatch,
  } = useProgress()
  const [availableTickets, setAvailableTickets] = useState<ITicket[] | false>(
    false,
  )
  const { ticket, quantity, billingDetails, purchasedTickets } = ticketState
  const selectedTicket = ticket as ITicket | false
  const quantitySelected = quantity as number
  const [iconBackOrClose, setIconBackOrClose] = useState(true)
  // const [purchasedTickets, setPurchasedTickets] = useState<IAttendeeTicket[]>()
  const { url, path } = useRouteMatch()
  const history = useHistory()
  useEffect(() => console.log('mounted checkout'), [])
  useEffect(() => {
    API.get<ITicket[]>(
      `Ticket/Event/${event && event.ID}${
        promoCode ? `?promo_code=${promoCode}` : ''
      }`,
    ).then((response) => {
      setAvailableTickets(response.data)
    })
  }, [event])
  Sentry.setContext('checkoutVars', ticketState)

  const [activeGuest, setActiveGuest] = React.useState(1)

  const onBackButtonClick = () => {
    switch (activeStep) {
      case 1:
        selectedTicket
          ? ticketDispatch({
              type: TicketReducerTypes.SetTicket,
              payload: false,
            })
          : props.onBack()
        break
      case 2:
        progressDispatch({ type: ProgressReducerTypes.SetStage, payload: 1 })
        break
      case 4:
        if (activeGuest > 1) setActiveGuest(activeGuest - 1)
        else
          progressDispatch({ type: ProgressReducerTypes.SetStage, payload: 3 })
        break
      case 5:
        progressDispatch({ type: ProgressReducerTypes.SetStage, payload: 4 })
        break
      default:
        progressDispatch({ type: ProgressReducerTypes.Reset })
        props.onBack()
    }
  }

  async function handleTicketSelection(
    paymentIntent?: PaymentIntent,
    billingDetails?: IBillingDetails,
  ) {
    if (selectedTicket !== false)
      if (selectedTicket.PaymentsEnabled) {
        if (paymentIntent && billingDetails) {
          // console.log(billingDetails)
          return handleCardPayment({
            billing: billingDetails,
            paymentIntent: paymentIntent,
          })
        }
        progressDispatch({
          type: ProgressReducerTypes.SetStage,
          payload: 2,
        })
        progressDispatch({
          type: ProgressReducerTypes.SetProps,
          payload: {
            onComplete: handleCardPayment,
            selectedQuantity: quantitySelected as number,
            selectedTicket: selectedTicket as ITicket,
            routeProps: props,
          },
        })
        return history.push(`${url}/pay` + history.location.search)
      } else {
        const repId = new URLSearchParams(window.location.search).get('repId')
        if (event !== undefined) {
          const postTickets = [...Array(quantitySelected)].map(() => {
            const newTicket: IAttendeeTicket = {
              ID: 0,
              AttendeeID: 0,
              TicketID: selectedTicket.ID,
              EventID: event.ID,
              StaffKey: repId !== null ? repId : '',
              // PromoCode: promoCode ? promoCode : '',
            }
            return newTicket
          })
          API.post<IAttendeeTicket[]>(
            `AttendeeTicket/Add${promoCode ? `?promo_code=${promoCode}` : ''}`,
            postTickets,
          )
            .then((response) =>
              ticketDispatch({
                type: TicketReducerTypes.SetPurchasedTicket,
                payload: response.data,
              }),
            )
            .catch((e) => Sentry.captureException(e))
          progressDispatch({ type: ProgressReducerTypes.SetStage, payload: 3 })
          return history.push(`${url}/contact` + history.location.search)
        }
      }
  }

  async function handleCardPayment(paymentResponse: {
    billing: IBillingDetails
    paymentIntent: PaymentIntent
  }) {
    if (selectedTicket !== false && event !== undefined) {
      console.log(paymentResponse.toString())
      ticketDispatch({
        type: TicketReducerTypes.SetBillingDetails,
        payload: paymentResponse.billing,
      })
      // setBillingDetails(paymentResponse.billing)
      ticketDispatch({
        type: TicketReducerTypes.SetPaymentIntent,
        payload: paymentResponse.paymentIntent,
      })
      // setPaymentIntent(paymentResponse.paymentIntent)

      const repId = new URLSearchParams(window.location.search).get('repId')
      const postTickets = [...Array(quantitySelected)].map(() => {
        const newTicket: IAttendeeTicket = {
          ID: 0,
          AttendeeID: 0,
          TicketID: selectedTicket.ID,
          EventID: event.ID,
          StripeTransactionID: selectedTicket.PaymentsEnabled
            ? paymentResponse.paymentIntent.id
            : '',
          StaffKey: repId !== null ? repId : '',
          PromoCode: promoCode ? promoCode : '',
        }
        return newTicket
      })
      API.post<IAttendeeTicket[]>(
        `AttendeeTicket/Add${promoCode ? `?promo_code=${promoCode}` : ''}`,
        postTickets,
      )
        .then((response) => {
          // setPurchasedTickets(response.data)
          ticketDispatch({
            type: TicketReducerTypes.SetPurchasedTicket,
            payload: response.data,
          })
          progressDispatch({ type: ProgressReducerTypes.SetStage, payload: 3 })
          progressDispatch({
            type: ProgressReducerTypes.SetProps,
            payload: {
              attendeeTickets: response.data,
              billingDetails: billingDetails,
              onContinue: handleContactDetails,
            },
          })
          history.replace(`${url}/contact` + history.location.search)
        })
        .catch((e) => Sentry.captureException(e))
      // history.replace('/' + history.location.search)
    }
  }

  async function handleContactDetails(contactDetails: IAttendee) {
    if (purchasedTickets !== undefined) {
      const attendeeToSave: IAttendee = {
        ...contactDetails,
        ID: purchasedTickets?.[0].AttendeeID!,
      }
      await API.post<IAttendee[]>('Attendee/Save', [attendeeToSave])
      API.post(`AttendeeTicket/SendEmail`, [
        {
          Email: contactDetails.Email,
          AttendeeTicketId: purchasedTickets[0].ID,
        },
      ])
      if (billingDetails === null) {
        ticketDispatch({
          type: TicketReducerTypes.SetBillingDetails,
          payload: {
            name: `${contactDetails.FirstName} ${contactDetails.LastName}`,
            email: contactDetails.Email!,
            phone: contactDetails.Phone!,
          },
        })
      }
      progressDispatch({
        type: ProgressReducerTypes.SetStage,
        payload: activeStep + 1,
      })
      return history.push(`${url}/guests` + history.location.search)
    }
  }

  if (event)
    return (
      <>
        <div className={`appCheckout active`}>
          <div className="appCheckoutContainer">
            <div className="cardNavigationControls">
              {activeStep <= 5 && (
                <>
                  <div
                    className={`iconBackOrClose ${
                      iconBackOrClose ? 'close' : ''
                    }`}
                    onClick={onBackButtonClick}
                    role="button"
                    tabIndex={0}
                  >
                    <svg
                      className="iconClose"
                      id="iconClose"
                      data-name="iconClose"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <path d="M61.78385,50,97.55858,14.22526A8.33244,8.33244,0,0,0,85.77473,2.44141L50,38.21613,14.22527,2.44141A8.33244,8.33244,0,0,0,2.44142,14.22526L38.21615,50,2.44142,85.77471A8.33244,8.33244,0,1,0,14.22527,97.55856L50,61.78383,85.77473,97.55856A8.33244,8.33244,0,0,0,97.55858,85.77471Z" />
                    </svg>
                    <svg
                      className="iconBack"
                      id="iconBack"
                      data-name="iconBack"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <path d="M41.8,46.5c-2,2-2,5.1,0,7.1l44,44c3.3,3.3,8.5,3.2,11.8,0c3.2-3.3,3.2-8.5,0-11.8L61.8,50l35.8-35.8 c3.3-3.3,3.3-8.5,0-11.8s-8.5-3.3-11.8,0L41.8,46.5z" />
                    </svg>
                  </div>
                  <ProgressBlips activeBlip={activeStep - 1} stepCount={5} />
                </>
              )}
            </div>

            <div className="appCheckoutEventOverview innerWidth">
              <div className="appCheckoutEventOverviewImage">
                <img src={event.Background} alt="Event Background" />
              </div>
              <div className="appCheckoutEventOverviewText">
                <h3>
                  {Intl.DateTimeFormat(navigator.language, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }).format(Date.parse(event.Date))}
                </h3>
                <h1>{event.Name}</h1>
              </div>
            </div>

            <div className={`appCheckoutCard${activeStep > 5 ? ' final' : ''}`}>
              {/* App Checkout Step 1: Ticket Selection */}
              <Switch>
                <Route exact path={path}>
                  <div
                    id="checkoutStep1 ticketSelectType"
                    className={`appCheckoutStep ${
                      activeStep === 1 ? 'active' : ''
                    }`}
                  >
                    <div className="appCheckoutStepHeading">
                      <h1 className="appCheckoutStepTitle">Tickets</h1>
                      <p className="appCheckoutStepDescription">
                        Please choose a ticket type and confirm your order.
                      </p>
                    </div>
                    {availableTickets && (
                      <TicketSelectorPanel
                        availableTickets={availableTickets}
                        selectedTicket={selectedTicket}
                        setSelectedTicket={(ticket: ITicket | false) => {
                          console.log(ticket)
                          ticketDispatch({
                            type: TicketReducerTypes.SetTicket,
                            payload: ticket,
                          })
                          // setSelectedTicket(ticket)
                        }}
                        quantitySelected={
                          quantitySelected ? (quantitySelected as number) : 1
                        }
                        setQuantitySelected={(quantity) => {
                          ticketDispatch({
                            type: TicketReducerTypes.SetQuantity,
                            payload: quantity,
                          })
                        }}
                        event={event as IEvent}
                        onContinue={(
                          paymentIntent?: PaymentIntent,
                          billingDetails?: IBillingDetails,
                        ) =>
                          handleTicketSelection(paymentIntent, billingDetails)
                        }
                        iconBackOrClose={iconBackOrClose}
                        setIconBackOrClose={setIconBackOrClose}
                      />
                    )}
                  </div>
                </Route>
                {/* App Checkout Step 2: Card Payment */}
                <Route
                  path={`${path}/pay`}
                  render={(props) => {
                    // implement state in url bar
                    return (
                      <div
                        id="checkoutStep2"
                        className={`appCheckoutStep ${
                          activeStep === 2 ? 'active' : ''
                        }`}
                      >
                        <div className="appCheckoutStepHeading">
                          <h1 className="appCheckoutStepTitle">
                            Pay with Card
                          </h1>
                          <p className="appCheckoutStepDescription">
                            Please enter your billing details.
                          </p>
                        </div>
                        {selectedTicket !== false && (
                          <StripeCardForm
                            onComplete={handleCardPayment}
                            selectedQuantity={quantitySelected as number}
                            selectedTicket={selectedTicket as ITicket}
                            routeProps={props}
                          />
                        )}
                      </div>
                    )
                  }}
                />
                {/* App Checkout Step 3: Primary Contact Details */}
                <Route path={`${path}/contact`}>
                  <div
                    id="checkoutStep3"
                    className={`appCheckoutStep ${
                      activeStep === 3 ? 'active' : ''
                    }`}
                  >
                    <div className="appCheckoutStepHeading">
                      <h1 className="appCheckoutStepTitle">Contact Details</h1>
                      <p className="appCheckoutStepDescription">
                        Thank you for your order! Please add your details to
                        your purchase here.
                      </p>
                    </div>
                    {purchasedTickets !== undefined && (
                      <ContactDetailsForm
                        attendeeTickets={purchasedTickets}
                        billingDetails={billingDetails}
                        onContinue={handleContactDetails}
                      />
                    )}
                  </div>
                </Route>
                {/* App Checkout Step 4: Guest Contact Details */}
                {undefined !== purchasedTickets && (
                  <Route path={`${path}/guests`}>
                    <div
                      className={`appCheckoutStep appCheckoutStepGuestDetails ${
                        activeStep === 4 ? 'active' : ''
                      }`}
                      id="checkoutStep4"
                    >
                      <AttendeeDetailPanel
                        activeGuest={activeGuest}
                        setActiveGuest={setActiveGuest}
                        purchasedTickets={purchasedTickets}
                        billingDetails={billingDetails!}
                        onContinue={() => {
                          progressDispatch({
                            type: ProgressReducerTypes.SetStage,
                            payload: activeStep + 1,
                          })
                          history.push(
                            `${url}/confirm` + history.location.search,
                          )
                        }}
                      />
                    </div>
                  </Route>
                )}
                {/* App Checkout Step 5: Confirmation and Feedback */};
                <Route path={`${path}/confirm`}>
                  <div
                    className={`appCheckoutStep ${
                      activeStep === 5 ? 'active' : ''
                    }`}
                    id="checkoutStep5"
                  >
                    <div className="ticketQuantityDisplay">
                      <div className="ticketQuantityIconsContainer">
                        {purchasedTickets?.map((ticket, idx) => (
                          <TicketIcon
                            key={ticket.AttendeeID}
                            attendeeTicket={ticket}
                            index={idx}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="appCheckoutStepHeading">
                      <h1 className="appCheckoutStepTitle">Order Confirmed</h1>
                      <p className="appCheckoutStepDescription">
                        Thank you for your purchase! We&apos;d really appreciate
                        the feedback on your experience today.
                      </p>
                    </div>
                    <FeedbackForm
                      onContinue={() => {
                        progressDispatch({
                          type: ProgressReducerTypes.SetStage,
                          payload: activeStep + 1,
                        })
                        progressDispatch({
                          type: ProgressReducerTypes.SetProps,
                          payload: {
                            purchasedTickets:
                              purchasedTickets as IAttendeeTicket[],
                          },
                        })
                        history.push(
                          `/tickets/` +
                            window.btoa(JSON.stringify(purchasedTickets)),
                        )
                      }}
                    />
                  </div>
                </Route>
                {/* App Checkout Step 6: View Tickets */}
                <Route path={`${path}/tickets`}>
                  <div
                    id="checkoutStep6"
                    className={`appCheckoutStep ${
                      activeStep === 6 ? 'active' : ''
                    }`}
                  >
                    {purchasedTickets && (
                      <TicketPurchasedSlider
                        purchasedTickets={purchasedTickets}
                      />
                    )}
                  </div>
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </>
    )
  else return <>An error occurred</>
}

export default AppCheckoutV2
