import { API } from '../utils'

export const sendFeedback = (
  attendeeTicketId: number,
  feedbackRating: number,
  // contactAllowed?: boolean,
) => {
  return API.post(
    `AttendeeTicket/UpdateFeedbackRating?attendee_ticket_id=${attendeeTicketId}&feedback_rating=${feedbackRating}`,
  )
}
