/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { API, useEvent } from '../../utils'
import InputField from '../InputField'
import ProceedButton from '../ProceedButton'

interface IContactDetailsFormProps {
  attendeeTickets: IAttendeeTicket[]
  billingDetails: IBillingDetails | null
  onContinue: (attendeeDetails: IAttendee) => void
}

const ContactDetailsForm = (
  props: IContactDetailsFormProps,
): React.ReactElement => {
  // const { billingDetails } = useTicket()
  const { event } = useEvent().eventState
  const [useManualEntry, setUseManualEntry] = useState(true)
  const [blocked, setBlocked] = useState(false)
  const [attendeeDetails, setAttendeeDetails] = useState<IAttendee>({
    ID: 0,
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
  })
  const [avatarString, setAvatarString] = useState<string>()
  useEffect(() => {
    if (props.billingDetails !== null) {
      setUseManualEntry(false)
      API.get<string>(
        `Utils/GenerateAvatar?firstName=${
          props.billingDetails.name.split(' ')[0]
        }&lastName=${
          props.billingDetails.name.split(' ')[1]
        }&background_color=${event!.ColourSecondary.split('#')[1]}&font_color=${
          event!.ColourBackground.split('#')[1]
        }`,
      ).then((response) => {
        setAvatarString(response.data)
      })
    }
    formEntriesValid()
  }, [props.billingDetails])
  useEffect(() => {
    formEntriesValid()
  }, [attendeeDetails])
  useEffect(() => {
    formEntriesValid()
  }, [useManualEntry])

  const onSubmit = (e: React.MouseEvent) => {
    e.preventDefault()

    if (useManualEntry) {
      API.get<string>(
        `Utils/GenerateAvatar?firstName=${attendeeDetails.FirstName}&lastName=${
          attendeeDetails.LastName
        }&background_color=${event!.ColourSecondary.split('#')[1]}&font_color=${
          event!.ColourBackground.split('#')[1]
        }`,
      ).then((response) => {
        props.onContinue({ ...attendeeDetails, Photo: response.data })
      })
    } else
      props.onContinue({
        ID: 0,
        FirstName: props.billingDetails?.name?.split(' ')[0],
        LastName: props.billingDetails?.name?.split(' ')[1],
        Email: props.billingDetails?.email,
        Phone: props.billingDetails?.phone,
        Photo: avatarString,
      })
  }

  const formEntriesValid = () => {
    if (useManualEntry)
      if (
        attendeeDetails.FirstName === '' ||
        attendeeDetails.LastName === '' ||
        attendeeDetails.Email === '' ||
        attendeeDetails.Phone === ''
      )
        return setBlocked(true)
    return setBlocked(false)
  }
  return (
    <>
      {props.billingDetails !== null && (
        <>
          <div
            className={`checkoutAttendeeDetailsOption ${
              !useManualEntry ? 'selected' : ''
            }`}
            id="attendeeDetailsAutomatic"
            onClick={() => setUseManualEntry(false)}
          >
            {/* <!-- @Liam this is selected by default --> */}
            <div className="checkoutAttendeeDetailsOptionCheck">
              <svg
                className={`bulletCheck ${!useManualEntry ? 'checked' : ''}`}
                data-name="bulletCheck"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
              >
                <path
                  className="bullet"
                  d="M11,22A11,11,0,1,1,22,11,10.96832,10.96832,0,0,1,11,22ZM11,2a9,9,0,1,0,9,9A8.96258,8.96258,0,0,0,11,2Z"
                />
                <circle className="bulletChecked" cx="11" cy="11" r="5" />
              </svg>
            </div>
            <div className="checkoutAttendeeDetailsOptionText">
              <div className="checkoutAttendeeDetailsOptionName">
                <h2>Automatically Use Billing Details</h2>
              </div>
              <h4 className="checkoutAttendeeDetailsOptionDescription">
                Recommended
              </h4>
            </div>
          </div>
          <div
            className={`attendeeProfileContainer ${
              !useManualEntry ? '' : 'hidden'
            }`}
          >
            <div className="attendeeProfilePhoto">
              {avatarString ? (
                <img
                  src={`data:image/png;base64,${avatarString}`}
                  alt="avatar"
                />
              ) : (
                <Avatar
                  name={props.billingDetails.name}
                  color={event?.ColourSecondary}
                  fgColor={event?.ColourBackground}
                  size="40"
                />
              )}
            </div>
            <div className="attendeeProfileDetails">
              <h2 className="attendeeDetailsFullName">
                {props.billingDetails.name}
              </h2>
              <h4 className="attendeeDetailsEmail">
                {props.billingDetails.email}
              </h4>
              <h4 className="attendeeDetailsPhone">
                {props.billingDetails.phone}
              </h4>
            </div>
          </div>
        </>
      )}
      <div
        className={`checkoutAttendeeDetailsOption ${
          useManualEntry ? 'selected' : ''
        }`}
        id="attendeeDetailsManual"
        onClick={() => setUseManualEntry(true)}
      >
        <div className="checkoutAttendeeDetailsOptionCheck">
          <svg
            className={`bulletCheck ${useManualEntry ? 'checked' : ''}`}
            data-name="bulletCheck"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 22"
          >
            <path
              className="bullet"
              d="M11,22A11,11,0,1,1,22,11,10.96832,10.96832,0,0,1,11,22ZM11,2a9,9,0,1,0,9,9A8.96258,8.96258,0,0,0,11,2Z"
            />
            <circle className="bulletChecked" cx="11" cy="11" r="5" />
          </svg>
        </div>
        <div className="checkoutAttendeeDetailsOptionText">
          <div className="checkoutAttendeeDetailsOptionName">
            <h2>Manually Add Details</h2>
          </div>
        </div>
      </div>

      <form className={`formContactDetails ${useManualEntry ? '' : 'hidden'}`}>
        {/* <!-- @Liam There's a new field in the ticket object which says whether attendeeDetails are required. Should be pretty easy to block progress and make all form fields required if this value is YES --> */}
        <div className="form-row form-row-double" id="addAttendeeNameRow">
          <InputField
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            label="First Name"
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setAttendeeDetails({
                ...attendeeDetails,
                FirstName: target !== null ? target.value : '',
              })
            }}
            required
          />
          <InputField
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            label="Last Name"
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setAttendeeDetails({
                ...attendeeDetails,
                LastName: target !== null ? target.value : '',
              })
            }}
            required
          />
        </div>
        <div className="form-row" id="addAttendeeEmailRow">
          <InputField
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            label="Email"
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setAttendeeDetails({
                ...attendeeDetails,
                Email: target !== null ? target.value : '',
              })
            }}
            required
          />
        </div>
        <div className="form-row" id="addAttendeePhoneRow">
          <InputField
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone"
            label="Phone"
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setAttendeeDetails({
                ...attendeeDetails,
                Phone: target !== null ? target.value : '',
              })
            }}
            required
          />
        </div>
      </form>

      <ProceedButton onClick={onSubmit} blocked={blocked}>
        Continue
      </ProceedButton>
    </>
  )
}

export default ContactDetailsForm
