import { DEBUG } from '../../../constants'
import { ProgressPageEnum } from '../progress.context'

export enum ProgressReducerTypes {
  SetPage = 'SET_PAGE',
  SetStage = 'SET_STAGE',
  SetProps = 'SET_PROPS',
  Reset = 'RESET',
}

type ProgressPagePayload = {
  [ProgressReducerTypes.SetPage]: ProgressPageEnum
  [ProgressReducerTypes.Reset]: undefined
}

type ProgressStagePayload = {
  [ProgressReducerTypes.SetStage]: number
  [ProgressReducerTypes.Reset]: undefined
}

type ProgressPropsPayload = {
  [ProgressReducerTypes.SetProps]: unknown
  [ProgressReducerTypes.Reset]: undefined
}

export type ProgressPageActions =
  ActionMap<ProgressPagePayload>[keyof ActionMap<ProgressPagePayload>]

export type ProgressStageActions =
  ActionMap<ProgressStagePayload>[keyof ActionMap<ProgressStagePayload>]

export type ProgressPropsActions =
  ActionMap<ProgressPropsPayload>[keyof ActionMap<ProgressPropsPayload>]

export const progressPageReducer = (
  state: ProgressPageEnum,
  action: ProgressPageActions | ProgressStageActions | ProgressPropsActions,
) => {
  switch (action.type) {
    case ProgressReducerTypes.SetPage:
      return action.payload
    case ProgressReducerTypes.Reset:
      return ProgressPageEnum.LandingPage
    default:
      return state
  }
}
export const progressStageReducer = (
  state: number,
  action: ProgressPageActions | ProgressStageActions | ProgressPropsActions,
) => {
  switch (action.type) {
    case ProgressReducerTypes.SetStage:
      return action.payload
    case ProgressReducerTypes.Reset:
      return 1
    default:
      return state
  }
}

export const progressPropsReducer = (
  state: unknown,
  action: ProgressPageActions | ProgressStageActions | ProgressPropsActions,
) => {
  switch (action.type) {
    case ProgressReducerTypes.SetProps:
      DEBUG && console.log('set props')
      DEBUG && console.log(action.type, action.payload)
      return action.payload
    case ProgressReducerTypes.Reset:
      return {}
    default:
      return state
  }
}
