import React, { useState } from 'react'
import InputField from '../../components/InputField'
import { API, useEvent } from '../../utils'

export interface IWaitingListV2Props {
  onBack: () => void
  onContinue: () => void
}

export function WaitingListV2(props: IWaitingListV2Props): React.ReactElement {
  const { event } = useEvent().eventState
  const [formComplete, setFormComplete] = useState(false)
  const [waitingListAttendee, setWaitingListAttendee] = useState<IAttendee>({
    ID: 0,
    FirstName: '',
    LastName: '',
    Email: '',
  })
  const [loading, setLoading] = useState(false)
  const [waitingListEntry, setWaitingListEntry] = useState<IWaitingList>()
  const checkFormComplete = () => {
    if (waitingListAttendee)
      if (
        waitingListAttendee.FirstName !== '' &&
        waitingListAttendee.LastName !== '' &&
        waitingListAttendee.Email !== ''
      )
        setFormComplete(true)
  }
  const onJoinClick = async () => {
    setLoading(true)
    if (event && formComplete) {
      const attendee = await API.post<IAttendee[]>(`Attendee/Save`, [
        {
          ...waitingListAttendee,
          ID: 0,
        },
      ])
      setWaitingListAttendee(attendee.data[0])
      const waitingListPost: IWaitingList = {
        ID: 0,
        AttendeeID: attendee.data[0].ID,
        EventID: event?.ID,
      }
      API.post<IWaitingList[]>(`WaitingList/Add`, [waitingListPost]).then(
        (response) => {
          setLoading(false)
          setWaitingListEntry(response.data[0])
        },
      )
    } else return setLoading(false)
  }
  if (event)
    return (
      <div>
        <div className="appCheckout active">
          <div className="appCheckoutContainer">
            <div className="cardNavigationControls">
              <div
                className={`iconBackOrClose close`}
                onClick={props.onBack}
                role="button"
                tabIndex={0}
              >
                <svg
                  className="iconClose"
                  id="iconClose"
                  data-name="iconClose"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <path d="M61.78385,50,97.55858,14.22526A8.33244,8.33244,0,0,0,85.77473,2.44141L50,38.21613,14.22527,2.44141A8.33244,8.33244,0,0,0,2.44142,14.22526L38.21615,50,2.44142,85.77471A8.33244,8.33244,0,1,0,14.22527,97.55856L50,61.78383,85.77473,97.55856A8.33244,8.33244,0,0,0,97.55858,85.77471Z" />
                </svg>
                <svg
                  className="iconBack"
                  id="iconBack"
                  data-name="iconBack"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <path d="M41.8,46.5c-2,2-2,5.1,0,7.1l44,44c3.3,3.3,8.5,3.2,11.8,0c3.2-3.3,3.2-8.5,0-11.8L61.8,50l35.8-35.8 c3.3-3.3,3.3-8.5,0-11.8s-8.5-3.3-11.8,0L41.8,46.5z" />
                </svg>
              </div>
            </div>
            <div className="appCheckoutEventOverview innerWidth">
              <div className="appCheckoutEventOverviewImage">
                <img src={event.Background} alt="Event Background" />
              </div>
              <div className="appCheckoutEventOverviewText">
                <h3>
                  {Intl.DateTimeFormat(navigator.language, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }).format(Date.parse(event.Date))}
                </h3>
                <h1>{event.Name}</h1>
              </div>
            </div>
            <div className="appCheckoutCard">
              {waitingListEntry === undefined && (
                <div
                  id="checkoutStep7"
                  className="appCheckoutStep active appCheckoutStepJoinWaitingList"
                >
                  <div className="appCheckoutStepHeading">
                    <h1 className="appCheckoutStepTitle">Join Waiting List</h1>
                    <p className="appCheckoutStepDescription">
                      To be notified when more tickets go on sale, please fill
                      in the below form.
                    </p>
                  </div>

                  <form className="formJoinWaitingList">
                    <div
                      className="form-row form-row-double"
                      id="addAttendeeNameRow"
                    >
                      <InputField
                        type="text"
                        id="addAttendeeFirstName"
                        name="addAttendeeFirstName"
                        placeholder="First Name"
                        label="First Name"
                        value={waitingListAttendee.FirstName}
                        onChange={(e) => {
                          const target = e.target as HTMLTextAreaElement
                          setWaitingListAttendee({
                            ...waitingListAttendee,
                            FirstName: target !== null ? target.value : '',
                          })
                          checkFormComplete()
                        }}
                        required
                        disabled={loading}
                      />
                      <InputField
                        type="text"
                        id="addAttendeeLastName"
                        name="addAttendeeLastName"
                        placeholder="Last Name"
                        label="Last Name"
                        value={waitingListAttendee.LastName}
                        onChange={(e) => {
                          const target = e.target as HTMLTextAreaElement
                          setWaitingListAttendee({
                            ...waitingListAttendee,
                            LastName: target !== null ? target.value : '',
                          })
                          checkFormComplete()
                        }}
                        required
                        disabled={loading}
                      />
                    </div>
                    <div className="form-row" id="addAttendeeEmailRow">
                      <InputField
                        type="email"
                        id="addAttendeeEmail"
                        name="addAttendeeEmail"
                        placeholder="Email"
                        label="Email"
                        value={waitingListAttendee.Email}
                        onChange={(e) => {
                          const target = e.target as HTMLTextAreaElement
                          setWaitingListAttendee({
                            ...waitingListAttendee,
                            Email: target !== null ? target.value : '',
                          })
                          checkFormComplete()
                        }}
                        required
                        disabled={loading}
                      />
                    </div>
                    <input
                      style={{ display: 'none' }}
                      type="submit"
                      onClick={onJoinClick}
                    />
                  </form>

                  <div
                    className={`buttonProceed${
                      formComplete || loading ? '' : ' blocked'
                    }`}
                    onClick={onJoinClick}
                  >
                    <p>Join Waiting List</p>
                  </div>
                </div>
              )}
              {waitingListEntry && (
                <div
                  id="checkoutStep7-1"
                  className="appCheckoutStep active appCheckoutStepJoinedWaitingList"
                >
                  <div className="appCheckoutStepHeading">
                    <h1 className="appCheckoutStepTitle">Thank you.</h1>
                    <p
                      className="appCheckoutStepDescription"
                      style={{ paddingBottom: '20px' }}
                    >
                      We have received your request and will notify you when any
                      more tickets are available.
                    </p>
                  </div>

                  <div className="buttonProceed" onClick={props.onContinue}>
                    <p>Close</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  else return <>Error</>
}
