export enum EventReducerTypes {
  SetEvent = 'SET_EVENT',
  SetPromoCode = 'SET_PROMOCODE',
  Reset = 'RESET',
}

type EventPayload = {
  [EventReducerTypes.SetEvent]: IEvent | Record<string, never>
  [EventReducerTypes.Reset]: undefined
}

type PromoCodePayload = {
  [EventReducerTypes.SetPromoCode]: string
  [EventReducerTypes.Reset]: undefined
}

export type EventActions =
  ActionMap<EventPayload>[keyof ActionMap<EventPayload>]

export type PromoCodeActions =
  ActionMap<PromoCodePayload>[keyof ActionMap<PromoCodePayload>]

export const eventReducer = (
  state: IEvent | Record<string, never>,
  action: EventActions | PromoCodeActions,
) => {
  switch (action.type) {
    case EventReducerTypes.SetEvent:
      return action.payload
    case EventReducerTypes.Reset:
      return {}
    default:
      return state
  }
}

export const promoCodeReducer = (
  state: string,
  action: EventActions | PromoCodeActions,
) => {
  switch (action.type) {
    case EventReducerTypes.SetPromoCode:
      return action.payload
    case EventReducerTypes.Reset:
      return ''
    default:
      return state
  }
}
