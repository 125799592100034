import React, { useState, useEffect } from 'react'
// import * as Sentry from '@sentry/react'
import { BACKEND_ENDPOINT } from '../../../constants'
// import { API } from '../../../utils'
import AddToApple from './AddToApple'

interface IAddToWalletProps {
  admissionId: number
}

function AddToWallet(props: IAddToWalletProps): React.ReactElement {
  const [appleActive, setAppleActive] = useState(false)
  const [appleWalletLink, setAppleWalletLink] = useState<string>()
  // const [googleWalletLink, setGoogleWalletLink] = useState<string>()

  /* const googleWalletSuccessHandler = () => {
    return
  }
  const googleWalletFailureHandler = () => {
    return
  } */

  useEffect(() => {
    if (window.ApplePaySession) {
      setAppleActive(true)
      setAppleWalletLink(
        `${BACKEND_ENDPOINT}/api/Wallet/ApplePass?attendee_ticket_id=${props.admissionId}`,
      )
    } else {
      console.log('google api has issues')
      /* API.get<string>(
        `Wallet/GooglePass?attendee_ticket_id=${props.admissionId}`,
      )
        .then((response) => {
          setGoogleWalletLink(response.data)
        })
        .catch((e) => Sentry.captureException(e))
      window.gapi.savetoandroidpay.render('addToGoogle', {
        jwt: googleWalletLink,
        onsuccess: googleWalletSuccessHandler,
        onfailure: googleWalletFailureHandler,
      }) */
    }
  }, [])

  /* useEffect(() => {
    if (googleWalletLink != null)
      window.gapi.savetoandroidpay.render('addToGoogle', {
        jwt: googleWalletLink,
        onsuccess: googleWalletSuccessHandler,
        onfailure: googleWalletFailureHandler,
      })
  }, [googleWalletLink]) */

  if (appleActive)
    return (
      <>
        <a href={appleWalletLink} download>
          <AddToApple style={{ cursor: 'pointer' }} />
        </a>
      </>
    )
  return (
    <>
      {/* <AddToGoogle style={{ cursor: 'pointer' }} /> */}
      {/* <div id="addToGoogle"></div> */}
    </>
  )
}

export default AddToWallet
