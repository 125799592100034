/* eslint-disable @typescript-eslint/no-explicit-any */
function isTicket(ticket: any): ticket is ITicket {
  return 'ID' in ticket
}

function isEvent(event: any): event is IEvent {
  return 'ID' in event
}

export const typechecker = {
  isTicket,
  isEvent,
}
