/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import { API, useEvent } from '../../utils'
import {
  PaymentIntent,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js'
import { useProcessing } from '../../utils/contexts/ProcessingContext'

const WalletButton = (props: {
  ticket: ITicket
  quantity: number
  event: IEvent
  onContinue: (
    paymentIntent: PaymentIntent,
    billingDetails: IBillingDetails,
  ) => void
}): React.ReactElement => {
  const stripe = useStripe()
  // const elements = useElements()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()
  const [clientSecret, setClientSecret] = useState('')
  const [error, setError] = useState<string | null>(null)
  // const [cardComplete, setCardComplete] = useState(false)
  // const [processing, setProcessing] = useState<boolean>(true)
  const { processing, setProcessing } = useProcessing()

  // const [paymentIntent, setPaymentIntent] = useState(null)
  const { promoCode } = useEvent().eventState
  // Getting Payment Intent
  useEffect(() => {
    setProcessing(true)
    API.post<string>(
      `Payments/Add?ticket_id=${props.ticket.ID}&quantity=${props.quantity}${
        promoCode && promoCode !== '' ? `&promo_code=${promoCode}` : ''
      }`,
    ).then(
      (response) => {
        setClientSecret(response.data)
        setProcessing(false)
      },
      (reqError) => {
        console.log(reqError.toString())
        // Sentry.captureException(reqError)
      },
    )
  }, [props.ticket, props.quantity, API])

  // Getting payment request for apple pay
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'IE',
        currency: props.ticket.Currency.toLowerCase(),
        total: {
          label: `${props.quantity}x ${props.ticket.Name} for ${props.event.Name}`,
          amount: props.ticket.SalePrice * props.quantity,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe])

  // setting right payment method handler
  useEffect(() => {
    if (paymentRequest !== undefined && clientSecret !== 'test') {
      paymentRequest.on(
        'paymentmethod',
        (ev: PaymentRequestPaymentMethodEvent) => {
          paymentRequestHandler(ev)
        },
      )
    }
  }, [clientSecret, paymentRequest])

  const paymentRequestHandler = async (
    ev: PaymentRequestPaymentMethodEvent,
  ) => {
    const currentClientSecret = clientSecret
    setProcessing(true)
    const { paymentIntent: localPaymentIntent, error: confirmError } =
      await stripe!.confirmCardPayment(
        currentClientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      )
    setProcessing(false)
    if (confirmError) {
      setError(confirmError.message || 'unknown error')
      ev.complete('fail')
    } else {
      ev.complete('success')
      if (localPaymentIntent?.status === 'requires_action') {
        const { error: localError } = await stripe!.confirmCardPayment(
          currentClientSecret,
        )
        if (localError) {
          setError('Payment failed, please try another card method')
        } else {
          // success
          setTimeout(() => {
            /* onContinue(localPaymentIntent, {
              email: ev.payerEmail,
              name: ev.payerName,
              phone: ev.payerPhone,
            }) */
            // console.log('completed with ', localPaymentIntent)
            props.onContinue(localPaymentIntent!, {
              email: ev.payerEmail!,
              name: ev.payerName!,
              phone: ev.payerPhone!,
            })
          }, 1500)
        }
      } else {
        // success
        setTimeout(() => {
          /* onContinue(localPaymentIntent, {
            email: ev.payerEmail,
            name: ev.payerName,
            phone: ev.payerPhone,
          }) */
          // console.log('completed with ', localPaymentIntent)
          props.onContinue(localPaymentIntent!, {
            email: ev.payerEmail!,
            name: ev.payerName!,
            phone: ev.payerPhone!,
          })
        }, 1500)
      }
    }
  }
  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: { paymentRequestButton: { type: 'book' } },
          }}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {processing && processing}
    </>
  )
}

const ErrorMessage = (props: { children: string }) => (
  <div className="error-message" role="alert">
    {props.children}
  </div>
)

export default WalletButton
