import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Helmet } from 'react-helmet'
import ScopeLogo from './assets/scopeLogo'
import { STRIPE_KEY } from './constants'
import AppCheckoutV2 from './pages/AppCheckoutV2/AppCheckoutV2'
import LandingV2 from './pages/LandingV2/LandingV2'
import { API, hexToRgb, typechecker, useQuery } from './utils'
import {
  ProcessingContext,
  useProcessing,
} from './utils/contexts/ProcessingContext'
import { Switch, Route, useHistory } from 'react-router-dom'
import WaitingListV2 from './pages/WaitingListV2'
import TicketViewer from './pages/TicketViewer'
import { useEvent } from './utils/contexts/event.context'
import { EventReducerTypes } from './utils/contexts/reducers/event.reducer'
import { AppContextProvider } from './utils/contexts/AppContext'
import {
  ProgressPageEnum,
  useProgress,
} from './utils/contexts/progress.context'
import { ProgressReducerTypes } from './utils/contexts/reducers/progress.reducer'

const stripePromise = loadStripe(STRIPE_KEY)

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fallback: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
}

function TicketApp() {
  const { eventState, eventDispatch } = useEvent()
  const { processing, setProcessing } = useProcessing()
  const {
    progressState: { page },
    progressDispatch,
  } = useProgress()

  const [brokerDetails, setBrokerDetails] = useState<IBroker>()
  const [locked, setLocked] = useState(false)
  const history = useHistory()
  const queryParams = useQuery()
  const wrapperRef = useRef<HTMLDivElement>(null)

  // Get Event on load
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('eventId') !== null) {
      setProcessing(true)
      console.log('getting')
      API.get<IEvent>(`Events/Get/${params.get('eventId')}`)
        .then((response) => {
          setProcessing(false)
          if (response.data !== null) {
            // seteventState.event(response.data)
            eventDispatch({
              type: EventReducerTypes.SetEvent,
              payload: response.data,
            })
            if (response.data.BrokerID)
              API.get<IBroker>(`Broker/Get/${response.data.BrokerID}`)
                .then((response) => {
                  setBrokerDetails(response.data)
                })
                .catch((e) => Sentry.captureException(e))
            // Setting Colours
            document.documentElement.style.setProperty(
              '--background-colour',
              hexToRgb(response.data.ColourBackground),
            )
            document.documentElement.style.backgroundColor = `rgb(${hexToRgb(
              response.data.ColourBackground,
            )})`
            document.documentElement.style.setProperty(
              '--primary-colour',
              hexToRgb(response.data.ColourPrimary),
            )
            document.documentElement.style.setProperty(
              '--secondary-colour',
              hexToRgb(response.data.ColourSecondary),
            )
            document.documentElement.style.setProperty(
              '--detail-colour',
              hexToRgb(response.data.ColourDetail),
            )
            document.documentElement.style.setProperty(
              '--system-colour',
              hexToRgb(response.data.SystemColour),
            )
            document.documentElement.style.setProperty(
              '--background-url',
              `url('${response.data.Background}')`,
            )
            Sentry.addBreadcrumb({
              category: 'message',
              message: `Loaded event ${params.get('eventId')} succesfully.`,
              level: Sentry.Severity.Info,
            })
          }
        })
        .catch((e) => Sentry.captureException(e))
    }
  }, [])
  Sentry.setContext('eventState.event', eventState)

  const lockWrapper = () => {
    wrapperRef.current !== null &&
      wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    setLocked(true)
  }

  const onContinue = (
    currentStep: ProgressPageEnum,
    selectStep?: ProgressPageEnum,
  ) => {
    switch (currentStep) {
      case ProgressPageEnum.LandingPage:
        lockWrapper()
        progressDispatch({
          type: ProgressReducerTypes.SetPage,
          payload: selectStep || ProgressPageEnum.CheckoutPage,
        })
        switch (selectStep) {
          case ProgressPageEnum.WaitingListPage:
            history.push('/waiting' + history.location.search)
            break
          default:
            history.push('/checkout' + history.location.search)
        }
        break
      default:
    }
  }

  const unlockWrapper = () => {
    wrapperRef.current !== null &&
      wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    setLocked(false)
  }

  const onBack = () => {
    unlockWrapper()
    progressDispatch({
      type: ProgressReducerTypes.SetPage,
      payload: ProgressPageEnum.LandingPage,
    })
    history.replace('/' + history.location.search)
  }

  const isEventQuery = () => {
    const eventString = queryParams.get('eventId')
    const { event } = eventState
    if (eventString === null) return false
    if (event !== {} && event.ID) {
      if (event.ID.toString() === eventString) return true
      else return false
    } else return false
  }

  if (
    eventState.event &&
    typechecker.isEvent(eventState.event) &&
    isEventQuery()
  ) {
    return (
      <div id="wrapper" className={locked ? 'locked' : ''} ref={wrapperRef}>
        <Helmet>
          <title>
            {eventState.event
              ? `${eventState.event.Name} | Scope Tickets`
              : 'Scope Tickets'}
          </title>
          <meta property="og:image" content={eventState.event.Cover} />
          <meta
            name="theme-color"
            content={eventState.event.ColourBackground}
          />
        </Helmet>
        <div className="appLandingContainer">
          {brokerDetails && (
            <a
              href={brokerDetails.Link}
              className="brokerContainer"
              target="_blank"
              rel="noreferrer"
            >
              <div className="brokerText">
                <p>
                  hosted by{' '}
                  <span className="brokerName">{brokerDetails.Name}</span>
                </p>
              </div>
              <div className="brokerLaunchIcon">
                <svg
                  id="iconBrokerOpenLink"
                  data-name="iconBrokerOpenLink"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <path d="M66.75,29H40.3a5,5,0,1,0,0,10c.09686,0,.18719-.02307.28271-.02851l.00061.02051c5.47706-.31085,10.33143-.58642,15.00519-.85168-8.453,8.18359-19.973,19.33642-25.06634,24.26739l.005.005a4.99308,4.99308,0,1,0,7.06012,7.06012l.005.005c4.931-5.09332,16.0838-16.61334,24.26739-25.06634-.26526,4.67376-.54083,9.52813-.85168,15.00518l.02051.00062C61.02309,59.51279,61,59.60312,61,59.7a5,5,0,1,0,10,0V33.25A4.25005,4.25005,0,0,0,66.75,29Z" />
                  <path d="M74.9999,0H25.00008A25,25,0,0,0,0,25V74.99985A25.00006,25.00006,0,0,0,25.00008,100H74.9999A25.00011,25.00011,0,0,0,100,74.99985V25A25.0001,25.0001,0,0,0,74.9999,0ZM90,75A15,15,0,0,1,75,90H25A15,15,0,0,1,10,75V25A15,15,0,0,1,25,10H75A15,15,0,0,1,90,25Z" />
                </svg>
              </div>
            </a>
          )}
          <section className="eventArtwork">
            <div className="artworkHeroContainer">
              <div className="artworkHero">
                <img src={eventState.event.Background} alt="Event Background" />
              </div>
            </div>
            <div className="artworkBackground">
              <div className="artworkBackgroundGradient" />
              <img src={eventState.event.Background} alt="Event Background" />
            </div>
          </section>
          <Switch>
            <Route exact path="/">
              <Sentry.ErrorBoundary
                fallback={({ error, componentStack, resetError }) => (
                  <React.Fragment>
                    <div>You have encountered an error</div>
                    <div>{error.toString()}</div>
                    <div>{componentStack}</div>
                    <button
                      onClick={() => {
                        resetError()
                      }}
                    >
                      Click here to reset!
                    </button>
                  </React.Fragment>
                )}
              >
                <LandingV2
                  onContinue={(selectPage) =>
                    onContinue(ProgressPageEnum.LandingPage, selectPage)
                  }
                  // pageActive={activePage === 0}
                  pageActive={page === ProgressPageEnum.LandingPage}
                  onValidPromoCode={(code) => {
                    eventDispatch({
                      type: EventReducerTypes.SetPromoCode,
                      payload: code,
                    })
                  }}
                />
              </Sentry.ErrorBoundary>
            </Route>
            <Route path="/checkout">
              <Sentry.ErrorBoundary>
                <Elements stripe={stripePromise}>
                  <AppCheckoutV2
                    // onContinue={onContinueLocal}
                    // pageActive={activePage === 1}
                    pageActive={page === ProgressPageEnum.CheckoutPage}
                    onBack={() => onBack()}
                  />
                </Elements>
              </Sentry.ErrorBoundary>
            </Route>
            <Route path="/waiting">
              {page === ProgressPageEnum.WaitingListPage && (
                <WaitingListV2
                  onBack={() => onBack()}
                  onContinue={() => onBack()}
                />
              )}
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
  if (window.location.search.includes('eventId') && processing) {
    return (
      <div className="wrapper">
        <div className="container" style={styles.container}>
          <div className="fallback" style={styles.fallback}>
            <ScopeLogo
              fill="#0500F2"
              style={{
                height: '60px',
                padding: '20px',
                marginBottom: '20px',
              }}
            />
            <h1>Loading your experience...</h1>
            <p>
              If you are waiting for too long check your internet or ensure you
              have copied the correct event
            </p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <Switch>
      <Route exact path="/tickets/:encodedTickets" component={TicketViewer} />
      <Route path="/">
        <div className="wrapper">
          <div className="container" style={styles.container}>
            <div className="fallback" style={styles.fallback}>
              <ScopeLogo
                fill="#0500F2"
                style={{
                  height: '60px',
                  padding: '20px',
                  marginBottom: '20px',
                }}
              />
              <h1>Please follow the link sent to you to purchase tickets</h1>
            </div>
          </div>
        </div>
      </Route>
    </Switch>
  )
}

function App() {
  const [processing, setProcessing] = useState(false)
  // Window size setter
  useEffect(() => {
    window.addEventListener('resize', () => {
      document.body.style.height = `${window.innerHeight}px`
    })
    return () =>
      window.removeEventListener('resize', () => {
        document.body.style.height = `${window.innerHeight}px`
      })
  }, [])
  return (
    <ProcessingContext.Provider value={{ processing, setProcessing }}>
      <AppContextProvider>
        <TicketApp />
      </AppContextProvider>
    </ProcessingContext.Provider>
  )
}

export default Sentry.withProfiler(App)
