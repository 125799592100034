import React, { useEffect, useState } from 'react'
import { Buffer } from 'buffer'
import LoadingAnimate from '../../assets/LoadingAnimate'
import { API, currencyLookup, formatVal, useEvent } from '../../utils'
import AddToWallet from './AddToWallet'
import Avatar from 'react-avatar'

export interface ITicketPurchasedProps {
  purchasedAttendeeTicket: IAttendeeTicket
  ticketCount: number
  activeTicket: number
  ticket: ITicket
}

export default function TicketPurchased(
  props: ITicketPurchasedProps,
): React.ReactElement {
  const [attendee, setAttendee] = useState<IAttendee>()
  const { event } = useEvent().eventState
  useEffect(() => {
    API.get<IAttendee>(
      `Attendee/Get/${props.purchasedAttendeeTicket.AttendeeID}`,
    ).then((response) => {
      setAttendee(response.data)
    })
  }, [props.purchasedAttendeeTicket, props.activeTicket])

  const priceString = () => {
    if (props.ticket.Total > 0) {
      return `${formatVal(
        props.ticket.BasePrice,
        currencyLookup(props.ticket.Currency.toLowerCase()),
      )} + ${formatVal(
        props.ticket.Fees,
        currencyLookup(props.ticket.Currency.toLowerCase()),
      )}`
    } else return 'Free'
  }
  if (props.purchasedAttendeeTicket)
    return (
      <div className="ticketPurchasedWrapper">
        <div className="ticketPurchased">
          <div className="upper">
            <div className="ticketStyling top">
              <svg
                className="left corner"
                id="ticketCornerTopLeft"
                data-name="ticketCornerTopLeft"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M100.00212,0V100h-100V95a5.00181,5.00181,0,0,1,5-5,85.00538,85.00538,0,0,0,85-85,5.00181,5.00181,0,0,1,5-5Z" />
              </svg>
              <div className="middle"></div>
              <svg
                className="right corner"
                id="ticketCornerTopRight"
                data-name="ticketCornerTopRight"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M100,100H0V0H5a5.00181,5.00181,0,0,1,5,5A85.00538,85.00538,0,0,0,95,90a5.00181,5.00181,0,0,1,5,5Z" />
              </svg>
            </div>
            <div className="content">
              <div className="attendeeDetails">
                <div className="attendeeAvatar">
                  {attendee?.Photo ? (
                    <img
                      src={attendee?.Photo} //{`data:image/png;base64,${attendee?.Photo}`}
                      alt="Avatar"
                    />
                  ) : (
                    <Avatar
                      // name={}
                      name={'1'}
                      color={event?.ColourSecondary}
                      fgColor={event?.ColourBackground}
                      size="50"
                    />
                  )}
                </div>
                <div className="attendeeName">
                  <h3>
                    Ticket {props.activeTicket + 1} of {props.ticketCount}
                  </h3>
                  <h2>
                    {attendee?.FirstName} {attendee?.LastName}
                  </h2>
                </div>
              </div>
            </div>
            <div className="ticketStyling bottom">
              <svg
                className="left corner"
                id="ticketCornerBottomLeft"
                data-name="ticketCornerBottomLeft"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M0,0H100V100H95a5.00181,5.00181,0,0,1-5-5A85.00538,85.00538,0,0,0,5,10,5.00181,5.00181,0,0,1,0,5Z" />
              </svg>
              <div className="middle"></div>
              <svg
                className="right corner"
                id="ticketCornerBottomRight"
                data-name="ticketCornerBottomRight"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M0,100V0H100V5a5.00181,5.00181,0,0,1-5,5A85.00538,85.00538,0,0,0,10,95a5.00181,5.00181,0,0,1-5,5Z" />
              </svg>
            </div>
          </div>
          <div className="lower">
            <div className="ticketStyling top">
              <svg
                className="left corner"
                id="ticketCornerTopLeft"
                data-name="ticketCornerTopLeft"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M100.00212,0V100h-100V95a5.00181,5.00181,0,0,1,5-5,85.00538,85.00538,0,0,0,85-85,5.00181,5.00181,0,0,1,5-5Z" />
              </svg>
              <div className="middle"></div>
              <svg
                className="right corner"
                id="ticketCornerTopRight"
                data-name="ticketCornerTopRight"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M100,100H0V0H5a5.00181,5.00181,0,0,1,5,5A85.00538,85.00538,0,0,0,95,90a5.00181,5.00181,0,0,1,5,5Z" />
              </svg>
            </div>
            <div className="content">
              <div className="ticketQRCodeContainer">
                {/* <img className="" src="images/qr-demo.png" /> */}
                <QRCode admissionID={props.purchasedAttendeeTicket.ID} />
                <div className="ticketQRCodeText">
                  <h3>Ticket</h3>
                  <h2>{props.ticket.Name}</h2>
                </div>
              </div>
              <div className="containerSelectedTicketButtons">
                <a className="buttonAddToWallet" href="">
                  <AddToWallet admissionId={props.purchasedAttendeeTicket.ID} />
                </a>
              </div>
              <div className="ticketInformationContainer">
                <div className="ticketInformation halfWidth">
                  <h4>Price</h4>
                  <h3>{priceString()}</h3>
                </div>
                <div className="ticketInformation halfWidth">
                  <h4>Status</h4>
                  <h3>{props.purchasedAttendeeTicket.Status}</h3>
                </div>
                <div className="ticketInformation fullWidth">
                  <h4>Note</h4>
                  <h3>{props.ticket.Note}</h3>
                </div>
              </div>
              <div className="ticketBranding">
                <h4>
                  Powered by
                  <a
                    href="https://scopetickets.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      id="pinSolid"
                      data-name="pinSolid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 538.21771 310.03082"
                    >
                      <path
                        d="M536.5,36.6,520.6,20.7,501.8,1.8a6.12757,6.12757,0,0,0-8.6-.1,320.58831,320.58831,0,0,1-224,90.4A320.58833,320.58833,0,0,1,45.2,1.7a6.12756,6.12756,0,0,0-8.6.1h0L19.2,19.2,1.8,36.5h0a5.54689,5.54689,0,0,0-.6.8c-.2.2-.2.4-.3.5a.75812.75812,0,0,0-.2.4,6.12558,6.12558,0,0,0,1.2,7L230.2,273.5h0L243.7,287l21.2,21.2a3.91509,3.91509,0,0,0,1,.8,6.07843,6.07843,0,0,0,7.7-.8l263-263A6.128,6.128,0,0,0,536.5,36.6ZM350.1,144.9l-80.9,80.9-80.9-80.9a384.59875,384.59875,0,0,0,80.9,8.6A386.00959,386.00959,0,0,0,350.1,144.9Z"
                        transform="translate(-0.095 0.0127)"
                      />
                    </svg>
                    <strong>Scope Tickets</strong>
                  </a>
                </h4>
              </div>
            </div>
            <div className="ticketStyling bottom">
              <svg
                className="left corner"
                id="ticketCornerBottomLeft"
                data-name="ticketCornerBottomLeft"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M0,0H100V100H95a5.00181,5.00181,0,0,1-5-5A85.00538,85.00538,0,0,0,5,10,5.00181,5.00181,0,0,1,0,5Z" />
              </svg>
              <div className="middle"></div>
              <svg
                className="right corner"
                id="ticketCornerBottomRight"
                data-name="ticketCornerBottomRight"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <path d="M0,100V0H100V5a5.00181,5.00181,0,0,1-5,5A85.00538,85.00538,0,0,0,10,95a5.00181,5.00181,0,0,1-5,5Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    )
  else return <></>
}

function QRCode(props: { admissionID: number }) {
  const [loading, setLoading] = useState(true)
  const [qrString, setQrString] = useState<string>()

  useEffect(() => {
    API.get<string>(`Utils/GetQrCodeAsSVG?content=${props.admissionID}`).then(
      (response) => {
        setQrString(Buffer.from(response.data).toString('base64'))
        setLoading(false)
      },
    )
  }, [API, props.admissionID])

  return (
    <>
      {loading ? (
        <LoadingAnimate fill="rgb(var(--primary-colour))" height={80} />
      ) : (
        <img src={`data:image/svg+xml;base64,${qrString}`} alt="qr-svg" />
      )}
    </>
  )
}
