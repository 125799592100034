import axios, { AxiosResponse } from 'axios'
import { BACKEND_ENDPOINT } from '../constants'

export class API {
  static async get<T>(route: string): Promise<AxiosResponse<T>> {
    return new Promise((resolve, reject) => {
      axios
        .get<T>(`${BACKEND_ENDPOINT}/api/${route}`)
        .then((response) => {
          // console.log(response);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  static async post<T>(route: string, body = {}): Promise<AxiosResponse<T>> {
    if (body === undefined) body = {}
    return new Promise((resolve, reject) => {
      axios
        .post<T>(`${BACKEND_ENDPOINT}/api/${route}`, body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  static async put<T>(route: string, body = {}): Promise<AxiosResponse<T>> {
    if (body === undefined) body = {}
    return new Promise((resolve, reject) => {
      axios
        .put<T>(`${BACKEND_ENDPOINT}/api/${route}`, body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => reject(error))
    })
  }
}
